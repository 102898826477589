import { useEffect } from "react";
import { useRouter } from "next/router";
import { useWeb3Onboard } from "@src/hooks/useWeb3Onboard";

import { Account, Button, Loader, WalletLogos } from "@src/components";
import { useAppSelector, useAppDispatch } from "@src/hooks";
import { setAttempted } from "@src/features/vesting";
import { setConnecting, setSafetyModalOpen } from "@src/features/layout";
import { is_browser } from "@src/constants";
import { CHAIN_ID, COMPANY_NAME, VESTING_DETAILS } from "@src/config";

const Home: React.FC = () => {
    const router = useRouter();
    const { account, connectedChain } = useWeb3Onboard();
    const connecting = useAppSelector((state) => state.layout.connecting);
    const vesting = useAppSelector((state) => state.vesting);

    const dispatch = useAppDispatch();

    useEffect(() => {
        let is_mounted = true;

        if (
            is_mounted &&
            is_browser &&
            account &&
            CHAIN_ID === parseInt(connectedChain?.id, 16)
        ) {
            const href = VESTING_DETAILS.length > 1 ? "/tokens" : "/vesting/0";
            router.push(href);
        }

        if (!vesting.attempted && !vesting.loading) {
            dispatch(setAttempted(true));
        }

        return () => {
            is_mounted = false;
        };
    }, [account, connectedChain?.id]);

    return (
        <>
            {(connecting && (
                <div className="max-w-screen-lg mx-auto my-5 p-5 text-center mt-10 flex-1">
                    <Loader height={20} className="mb-8" />

                    <h1 className="text-5xl mb-10">
                        <strong>CONNECTING...</strong>
                    </h1>
                    <h5 className="text-lg mb-16">
                        Please select your wallet and follow <br /> the
                        instructions to complete the setup
                    </h5>
                    <Button onClick={() => dispatch(setConnecting(false))} dark>
                        Cancel
                    </Button>
                    <br />
                    <button
                        className="underline cursor-pointer mt-10 mb-5 text-secondary"
                        onClick={() => dispatch(setSafetyModalOpen(true))}
                    >
                        Is this page safe to use?
                    </button>
                </div>
            )) || (
                <>
                    <WalletLogos />
                    <div className="max-w-screen-lg mx-auto p-5 text-center">
                        <h1 className="text-5xl mb-5">
                            <strong>WELCOME</strong>
                        </h1>
                        <h5 className="text-lg mb-5 text-secondary">
                            This is an official {COMPANY_NAME} investor
                            dashboard
                        </h5>
                        <h5 className="text-lg mb-10">
                            To use the Investor dashboard, <br />
                            please connect your wallet
                        </h5>

                        <Account />
                        <br />
                        <button
                            className="underline cursor-pointer mt-10 mb-5 text-secondary"
                            onClick={() => dispatch(setSafetyModalOpen(true))}
                        >
                            Is this page safe to use?
                        </button>
                    </div>
                </>
            )}
        </>
    );
};

export default Home;
